@import "./variables";

// Utility classes
.pointer {
  cursor: pointer;
}

table tbody tr:hover {
  background-color: rgba(255, 54, 68, 0.1);
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}

.ml-5px {
  margin-left: 5px;
}

.MuiTableRow-head th {
  font-weight: bold !important;
  font-size: 15px;
  //background-color: $pink;
  background-color: rgba(121, 198, 236, 0.5);
}

.MuiTypography-h6 {
  font-size: 18px !important;
}

.MuiTableCell-sizeSmall {
  padding: 6px 2px 6px 16px !important;
}

.MuiCardHeader-root {
  border-bottom: 1px solid #ccc;
}

.MuiList-padding {
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}

.select-azienda > div {
  color: $secondary;
}

.select-azienda > .MuiInputBase-root.Mui-disabled {
  color: $secondary;
}

.select-azienda > .MuiInputBase-root.Mui-disabled > fieldset {
  border-color: $secondary;
}

.select-azienda > .MuiInputBase-root.Mui-disabled > div > button > span > svg {
  display: none;
}

.select-azienda > .MuiFormLabel-root.Mui-disabled {
  color: $secondary;
}

.select-azienda > label {
  color: $secondary;
}

.select-azienda > div > fieldset {
  border-color: $secondary;
}

.select-azienda
  > .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: $secondary;
}

.select-azienda > div > div > button > span > svg > path {
  color: $secondary;
}

.select-azienda > div > svg {
  color: $secondary;
}

.card-add {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px !important;
}

.card-filters {
  padding-top: 10px;
  padding-bottom: 10px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.justify-content-center {
  justify-content: center;
}

.pt-5 {
  padding-top: 5px;
}

.card-login {
  background-color: $primary;
  color: #fff;
}

.link-primary {
  color: $primary;
  font-weight: bold;
}

.link-primary:hover {
  color: $secondary;
  font-weight: bold;
}

.registrazione-sfondo {
  position: fixed; 
  top: 0; 
  left: 0; 

  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
}

.MuiTableRow-root[isExcluded=true] {
  background-color: #d3d3d3;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}